:root {
  --color-BS5: #041d2d;
  --color-BS4: #06283d;
  --color-BS4BIS: #06283d;
  --color-BS3: #256d85;
  --color-BS2: #47b5ff;
  --color-BS25: #47b5ffbd;
  --color-BS1: #dff6ff;
  --DerriereFlickity :#061b2c;

  --effet_logo: drop-shadow(2px 4px 6px black) brightness(1.2);

  --color-1000: #000;
  --color-900: #1a1a1a;
  --color-800: #333;
  --color-700: #5a5a5a;
  --color-500: #989898;
  --color-300: #cacaca;
  --color-100: #f4f4f4;
  --color-000: #fff;
  --color-chapterSelectBG: #f4f4f4;
  --color-series: #1a1a1a;
  --color-image-fade: hsla(0, 0%, 100%, 0)
}

* {
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  image-rendering: optimizeSpeed;
}

body {
  color: var(--color-BS1);
  background-color: var(--color-BS4);
  position: absolute;
    width: 100%;
    height: 100%;
}

.container-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  grid-template-rows: 1fr 1fr;
  gap: 4em 4em;
  grid-auto-flow: row;
  width: 100%;
  padding: 2em 9em 3em 8em;
}
.container-preview a {
    color: var(--color-000);
  }

.container-preview img {
  transition: all 0.33s cubic-bezier(0.21, 0.60, 0.08, 1.16);
  border-radius: 8px;
  height: 70%;
  width: 100%;
}

.container-preview img:hover {

  filter: contrast(1.16) brightness(0.96) drop-shadow(1px 2px 0.5rem var(--color-1000));
  transform: translateY(-12px) scale(1.026);
}

.allserie {
  margin: 2em;
  grid-column: 2/-1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

img {
  height: 100%;
  object-fit: cover;
  object-position: top;

}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ***** Global Styles - End ***** */
/* ***** Specific Styles - Start ***** */
.container {
  height: 100vh;
}


.moon-mask {
  position: absolute;
  width: 55px;
  height: 55px;
  margin: -74px 0 0 34px;
  background: #1775a8eb;
  border-radius: 50%;
}

.circles-wrapper .circle {
  position: absolute;
  width: 4px;
  height: 8px;
  background: #fff;
  border-radius: 10px;
}

.circles-wrapper .circle:first-child {
  margin: -57px 0 0 27px;
}

.circles-wrapper .circle:nth-child(2) {
  margin: -9px 0 0 28px;
}

.circles-wrapper .circle:nth-child(3) {
  margin: -32px 0 0 51px;
  transform: rotate(90deg);
}

.circles-wrapper .circle:nth-child(4) {
  margin: -33px 0 0 4px;
  transform: rotate(90deg);
}

.circles-wrapper .circle:nth-child(5) {
  margin: -14px 0 0 10px;
  transform: rotate(45deg);
}

.circles-wrapper .circle:nth-child(6) {
  margin: -14px 0 0 45px;
  transform: rotate(320deg);
}

.circles-wrapper .circle:nth-child(7) {
  margin: -50px 0 0 10px;
  transform: rotate(135deg);
}

.circles-wrapper .circle:nth-child(8) {
  margin: -50px 0 0 45px;
  transform: rotate(235deg);
}

a {
  text-decoration: none;
}

main {
  background: var(--bg, white);
  color: var(--text, black);
}

main {
  --gradDark: #c7ffdd;
  --gradLight: #fbd988;
  background: linear-gradient(to bottom, var(--gradDark), var(--gradLight));
  padding: 120px 40px 40px 40px;
  min-height: 100vh;
  text-align: center;
}

.wrapper {
  max-width: 700px;
}

@media screen and (max-width: 1090px) {


  .credit p {
    margin-left: 25px;
    font-size: 18px;
  }

  .plus {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    margin-right: 29px;
    font-size: 18px;
  }

  .plus img {
    width: 30px;
    height: 30px;
  }

  .twitter {
    width: 35px !important;
    height: 28px !important;
  }
}